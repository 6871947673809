
import { computed, defineComponent, PropType } from "vue";
import { AppActionTypes, useStore } from "@/store";
import { SubscriptionModel, SubscriptionType } from "@/models";
import { Form, FormActions } from "vee-validate";
import swal from "sweetalert";
import { getModelStateFromResponse, hasErrors } from "@elite/validation";
import { event } from "vue-gtag";

import KendoModal from "./account/KendoModal.vue";

// This component was designed to be used with the CancelSubscriptionButton. That component
// will emit an event when the cancel subscription button is clicked, and it will include the
// subscription that is intended to be canceled. This component takes that subscription as a
// parameter and also used v-model to determine when to display the modal.
export default defineComponent({
    components: {
        "kendo-modal": KendoModal,
        "v-form": Form
    },
    props: {
        subscription: {
            type: Object as PropType<SubscriptionModel | null>,
            required: false,
            default: null
        },
        modelValue: {
            type: Boolean,
            required: true
        }
    },
    emits: ["update:modelValue"],
    setup(props, context) {
        const { dispatch, getters } = useStore();
        const showModal = computed({
            get: () => props.modelValue,
            set: (value: boolean) => {
                context.emit("update:modelValue", value);
            }
        });

        const onModalCancel = (): void => {
            event("lms_cancel_subscription_keep");
        };

        const onModalConfirm = (): void => {
            event("lms_cancel_subscription_confirm");
        };

        const onModalClose = (): void => {
            showModal.value = false;
            event("lms_cancel_subscription_close");
        };

        const onSubmit = async (
            values: SubscriptionModel,
            actions: FormActions<Record<string, unknown>>
        ): Promise<void> => {
            const response = await dispatch(AppActionTypes.cancelSubscription, {
                type: props.subscription?.type as SubscriptionType,
                id: props.subscription?.id || ""
            });

            showModal.value = false;

            if (!hasErrors(response)) {
                await swal({
                    title: "Cancelled Subscription",
                    content: {
                        element: "div",
                        attributes: {
                            className: "text-start",
                            innerHTML: `
                                <p>Your cancellation is complete. It may take 10-15 minutes for the subscription status to be updated.</p>
                                <br/>
                                <p>Please note, if you have other subscriptions that you would like to cancel, you must process those cancellations individually. When signed in to <a href="https://members.espired.com">members.espired.com</a>, simply click on "Manage My Account" where you can see the status of your subscriptions. If you do not take action to cancel them, they will remain active.</p>
                            `
                        }
                    },
                    icon: "success"
                });
            } else {
                const modelState = getModelStateFromResponse(response, actions);

                const error =
                    modelState && modelState.modelErrors
                        ? modelState.modelErrors.join(" ")
                        : "An error has occurred while attempting to cancel the subscription.";

                await swal({
                    title: "Error Cancelling Subscription",
                    text: error,
                    icon: "error"
                });
            }
        };

        return {
            loading: computed(() => getters.loading),
            showModal,
            onModalCancel,
            onModalConfirm,
            onModalClose,
            onSubmit
        };
    }
});
