
import { PropType, defineComponent } from "vue";
import { event } from "vue-gtag";
import { useRouter } from "vue-router";

import { routeNames } from "@/router/routeNames";
import { SubscriptionModel, SubscriptionType } from "@/models";
import { AppActionTypes, useStore } from "@/store";

// This component is designed to be used with CancelSubscriptionModal. This component
// emits an event (showCancelModal) which is triggered when the user clicks the cancel
// button and there is not a cancellation offer. The event also contains the subscription
// so the even CancelSubscriptionModal knows which subscription to cancel.
export default defineComponent({
    props: {
        subscription: {
            type: Object as PropType<SubscriptionModel>,
            required: true
        },
        buttonClass: {
            type: String,
            required: false,
            default: ""
        },
        showIcon: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    emits: ["cancelSubscriptionClicked"],
    setup(props, context) {
        const router = useRouter();
        const store = useStore();

        const onCancelClick = async (): Promise<void> => {
            const { dispatch, state } = store;
            // we need the subscriptionDetails here because cancellation offer is not always mapped in the subscription object
            await dispatch(AppActionTypes.getSubscriptionDetail, {
                type: props.subscription.type as SubscriptionType,
                id: props.subscription.id ?? ""
            });
            if (props.subscription?.cancellationOffer || state.subscriptionDetails[props.subscription.id ?? ""].cancellationOffer) {
                await router.push({
                    name: routeNames.cancellationOffer,
                    params: {
                        subscriptionId: props.subscription.id,
                        type: props.subscription.type
                    }
                });
            } else {
                context.emit("cancelSubscriptionClicked", props.subscription);
                event("lms_cancel_subscription_start");
            }
        };

        return {
            onCancelClick
        };
    }
});
