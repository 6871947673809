import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading_indicator = _resolveComponent("loading-indicator")!
  const _component_cancel_button = _resolveComponent("cancel-button")!
  const _component_save_button = _resolveComponent("save-button")!
  const _component_k_dialog_actions_bar = _resolveComponent("k-dialog-actions-bar")!
  const _component_k_dialog = _resolveComponent("k-dialog")!

  return (_ctx.modelValue)
    ? (_openBlock(), _createBlock(_component_k_dialog, {
        key: 0,
        title: _ctx.title,
        width: _ctx.width,
        onClose: _ctx.hide
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default"),
          (_ctx.loading || _ctx.submitting)
            ? (_openBlock(), _createBlock(_component_loading_indicator, { key: 0 }))
            : _createCommentVNode("", true),
          (_ctx.showCancelButton || _ctx.showSaveButton)
            ? (_openBlock(), _createBlock(_component_k_dialog_actions_bar, {
                key: 1,
                class: _normalizeClass({ 'd-grid gap-2': _ctx.gapButtons })
              }, {
                default: _withCtx(() => [
                  (_ctx.showCancelButton)
                    ? (_openBlock(), _createBlock(_component_cancel_button, {
                        key: 0,
                        text: _ctx.cancelButtonText,
                        icon: _ctx.cancelButtonIcon,
                        "is-cancel-pop-up": _ctx.isCancelPopUp,
                        onClick: _withModifiers(_ctx.cancel, ["prevent"])
                      }, null, 8, ["text", "icon", "is-cancel-pop-up", "onClick"]))
                    : _createCommentVNode("", true),
                  (_ctx.showSaveButton)
                    ? (_openBlock(), _createBlock(_component_save_button, {
                        key: 1,
                        submitting: _ctx.submitting,
                        text: _ctx.saveButtonText,
                        icon: _ctx.saveButtonIcon,
                        "is-cancel-pop-up": _ctx.isCancelPopUp,
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('modal-save')))
                      }, null, 8, ["submitting", "text", "icon", "is-cancel-pop-up"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["class"]))
            : _createCommentVNode("", true)
        ]),
        _: 3
      }, 8, ["title", "width", "onClose"]))
    : _createCommentVNode("", true)
}