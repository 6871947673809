
import { defineComponent } from "vue";

export default defineComponent({
    name: "subscription-status",
    props: {
        status: {
            type: String,
            required: true,
            default: "Active"
        }
    }
});
