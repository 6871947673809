
import { computed, defineComponent } from "vue";
import { Dialog } from "@progress/kendo-vue-dialogs/dist/npm/Dialog";
import { DialogActionsBar } from "@progress/kendo-vue-dialogs/dist/npm/DialogActionsBar";

import { useStore } from "@/store";

export default defineComponent({
    name: "kendo-modal",
    components: {
        "k-dialog": Dialog,
        "k-dialog-actions-bar": DialogActionsBar
    },
    props: {
        title: {
            type: String,
            required: true
        },
        width: {
            type: String,
            required: false,
            default: "auto"
        },
        gapButtons: { type: Boolean, required: false, default: false },
        submitting: {
            type: Boolean,
            required: false,
            default: false
        },
        modelValue: {
            type: Boolean,
            required: true
        },
        showSaveButton: {
            type: Boolean,
            required: false,
            default: true
        },
        showCancelButton: {
            type: Boolean,
            required: false,
            default: true
        },
        saveButtonText: {
            type: String,
            required: false,
            default: "Save"
        },
        saveButtonIcon: {
            type: String,
            required: false,
            default: "save"
        },
        cancelButtonText: {
            type: String,
            required: false,
            default: "Cancel"
        },
        cancelButtonIcon: {
            type: String,
            required: false,
            default: ""
        },
        isCancelPopUp: {
            type: Boolean,
            required: false,
            default: false
        },
    },
    emits: ["update:modelValue", "modal-save", "modal-cancel", "modal-hide"],
    setup(props, context) {
        const { getters } = useStore();

        const hide = (): void => {
            context.emit("modal-hide");
            context.emit("update:modelValue", false);
        };

        const cancel = (): void => {
            context.emit("modal-cancel");
            context.emit("update:modelValue", false);
        };

        return { hide, cancel, loading: computed(() => getters.loading) };
    }
});
